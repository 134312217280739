/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Ralway", sans-serif;
  max-width: 90rem !important;
  margin: 0 auto;
}

body{
  scrollbar-width: thin;
  scrollbar-color: #001E4D #ffffff;
  /* border-radius: 0.625rem; */

}

::-webkit-scrollbar {
  width: 0.625rem;
  /* border-radius: 0.3125rem; */
}

::-webkit-scrollbar-thumb {
  background-color: #F7911D;
  /* border-radius: 0.3125rem; */
}

::-webkit-scrollbar-track {
  background-color: #001E4D;
}

.mat-drawer-inner-container{
  scrollbar-width: thin;
  scrollbar-color: #F7911D #001E4D;
  /* border-radius: 0.625rem; */

}

.mat-drawer-inner-container::-webkit-scrollbar {
  width: 0.625rem;
  /* border-radius: 0.3125rem; */
}

.mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background-color: #F7911D;
  /* border-radius: 0.3125rem; */
}

.mat-drawer-inner-container::-webkit-scrollbar-track {
  background-color: #001E4D;
}

@media screen and (max-width: 576px){
  html{
    font-size: 13px;
  }
}


.grecaptcha-badge {
  z-index: 1 !important;
}
